/* eslint-disable no-irregular-whitespace */
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :lang(fr) {
    quotes: "« " " »"
  }
  :lang(de) {
    quotes: "„" "“"
  }

  * {
    box-sizing: border-box;
  }

  body {
    padding: 0;
    margin: 0;
    overflow-x: hidden !important;
    font-family: "HN Text" !important;
    font-weight: 400;
    font-feature-settings: "ss01", "ss04", "ss08";
  }

  body > #stoomlink-root {
    min-height: 100%;
   }
`;

export default GlobalStyle;
