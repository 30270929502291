import { createGlobalStyle } from 'styled-components';
import HNTextRegularWoff2 from '../assets/fonts/textRegular.woff2';
import HNTextRegularWoff from '../assets/fonts/textRegular.woff';
import HNTextMediumWoff2 from '../assets/fonts/textMedium.woff2';
import HNTextMediumWoff from '../assets/fonts/textMedium.woff';
import HNTextBoldWoff2 from '../assets/fonts/textBold.woff2';
import HNTextBoldWoff from '../assets/fonts/textBold.woff';
import HNDisplayRegularWoff2 from '../assets/fonts/displayRegular.woff2';
import HNDisplayRegularWoff from '../assets/fonts/displayRegular.woff';
import HNDisplayMediumWoff2 from '../assets/fonts/displayMedium.woff2';
import HNDisplayMediumWoff from '../assets/fonts/displayMedium.woff';
import HNDisplayBoldWoff2 from '../assets/fonts/displayBold.woff2';
import HNDisplayBoldWoff from '../assets/fonts/displayBold.woff';
import HNDisplayExtraBoldWoff2 from '../assets/fonts/displayExtraBold.woff2';
import HNDisplayExtraBoldWoff from '../assets/fonts/displayExtraBold.woff';

const FontStyle = createGlobalStyle`
  @font-face {
    font-family: "HN Text";
    src: ${() => `url(${HNTextRegularWoff2}) format("woff2"), url(${HNTextRegularWoff}) format("woff");`};
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "HN Text";
    src: ${() => `url(${HNTextMediumWoff2}) format("woff2"), url(${HNTextMediumWoff}) format("woff");`};
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "HN Text";
    src: ${() => `url(${HNTextBoldWoff2}) format("woff2"), url(${HNTextBoldWoff}) format("woff");`};
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "HN Display";
    src: ${() => `url(${HNDisplayRegularWoff2}) format("woff2"), url(${HNDisplayRegularWoff}) format("woff");`};
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "HN Display";
    src: ${() => `url(${HNDisplayMediumWoff2}) format("woff2"), url(${HNDisplayMediumWoff}) format("woff");`};
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "HN Display";
    src: ${() => `url(${HNDisplayBoldWoff2}) format("woff2"), url(${HNDisplayBoldWoff}) format("woff");`};
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "HN Display";
    src: ${() => `url(${HNDisplayExtraBoldWoff2}) format("woff2"), url(${HNDisplayExtraBoldWoff}) format("woff");`};
    font-weight: 800;
    font-style: normal;
  }
`;

export default FontStyle;
