import React, { lazy, Suspense } from 'react';
import PageSuspense from '../components/PageSuspense';

export const makeLazy = (ImportStatement) => {
  const PageComponent = lazy(ImportStatement);

  return (props) => (
    <Suspense fallback={<PageSuspense />}>
      <PageComponent {...props} />
    </Suspense>
  );
};


export default {
  makeLazy,
};
