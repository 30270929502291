import { useState, useEffect, useCallback } from 'react';

const getPreference = (defaultScheme) => {
  if (('matchMedia' in window)) {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const prefersLight = window.matchMedia('(prefers-color-scheme: light)').matches;

    if (prefersDark) return 'dark';
    if (prefersLight) return 'light';
    return defaultScheme;
  }
  return defaultScheme;
};

export const useColorScheme = (defaultScheme = 'light', autoSwitch = false) => {
  const [scheme, setScheme] = useState(getPreference(defaultScheme));

  const setListenerDark = useCallback((event) => {
    if (event.matches) setScheme('dark');
  }, [setScheme]);
  const setListenerLight = useCallback((event) => {
    if (event.matches) setScheme('light');
  }, [setScheme]);
  const setListenerNoPref = useCallback((event) => {
    if (event.matches) setScheme(defaultScheme);
  }, [setScheme, defaultScheme]);

  useEffect(() => {
    const preference = getPreference(defaultScheme);
    setScheme(preference);

    if (autoSwitch) {
      window.matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', setListenerDark);
      window.matchMedia('(prefers-color-scheme: light)')
        .addEventListener('change', setListenerLight);
      window.matchMedia('(prefers-color-scheme: no-preference)')
        .addEventListener('change', setListenerNoPref);
    }

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', setListenerDark);
      window.matchMedia('(prefers-color-scheme: light)')
        .removeEventListener('change', setListenerLight);
      window.matchMedia('(prefers-color-scheme: no-preference)')
        .removeEventListener('change', setListenerNoPref);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return scheme;
};

export default useColorScheme;
