import React from 'react';
import { Helmet } from 'react-helmet';
import AppProviders from './AppProviders';
import Router from './Router';
import config from './config';
import GlobalStyle from './theme/global';
import FontStyle from './theme/fonts';

const AppRoot = () => {
  return (
    <>
      <Helmet
        titleTemplate={`%s – ${config.helmet.partialTitle}`}
        defaultTitle={config.helmet.defaultTitle}
      />
      <FontStyle />
      <GlobalStyle />
      <AppProviders
        config={config}
      >
        <Router />
      </AppProviders>
    </>
  );
};


export default AppRoot;
