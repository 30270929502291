// @flow
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

type Props = {
  children?: Node,
}

const AppShell = ({ children }: Props) => {
  return (
    <Container>
      {children}
    </Container>
  );
};

AppShell.defaultProps = {
  children: null,
};

export default AppShell;
