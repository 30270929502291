import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import routes from './routes';
import AppShell from './components/AppShell';

const Router = () => {
  return (
    <BrowserRouter>
      <AppShell>
        <Switch>
          {
            routes.map((route) => (
              <Route
                key={route.path}
                {...route}
                component={null}
                render={(routeProps) => (
                  <route.component {...routeProps} {...route} />
                )}
              />
            ))
          }
        </Switch>
      </AppShell>
    </BrowserRouter>
  );
};

export default Router;
