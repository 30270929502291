import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import fr from './fr.json';
import nl from './nl.json';

const resources = {
  fr: {
    translation: fr,
  },
  nl: {
    translation: nl,
  },
  en: {
    translation: en,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      lookupCookie: 'stmlk.lang',
      lookupLocalStorage: 'stmlk.lang',
      lookupSessionStorage: 'stmlk.lang',
    },
  });

export default i18n;
