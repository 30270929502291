import React from 'react';
import styled from 'styled-components';
import LoadingIndicator from 'stoomlink-commons-ui/LoadingIndicator';

const Container = styled.div`
  display: flex;
  place-items: center;
  place-content: center;
  flex-grow: 1;
  height: 100%;
  width: 100%;
`;

function LoadingSuspense() {
  return (
    <Container>
      <LoadingIndicator />
    </Container>
  );
}

export default LoadingSuspense;
