/* eslint-disable react/prop-types */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import ThemeProvider from 'stoomlink-commons-ui/ThemeProvider';
import LayersManager from 'stoomlink-commons-ui/LayersManager';
import i18n from './i18n';
import theme from './theme/config';
import { useColorScheme } from './hooks/useColorScheme';

const AppProviders = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, config } = props;
  const colorScheme = useColorScheme(config.defaultTheme, true);

  return (
    <ThemeProvider
      themeStyle={colorScheme}
      theme={theme ? {
        ...theme,
        colors: theme.colors[colorScheme],
        colorScheme,
      } : {}}
    >
      <I18nextProvider i18n={i18n}>
        <LayersManager>
          <Router>
            {children}
          </Router>
        </LayersManager>
      </I18nextProvider>
    </ThemeProvider>
  );
};


export default AppProviders;
