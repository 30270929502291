import { makeLazy } from './utils/routes';

export default [
  {
    path: '/',
    exact: true,
    title: 'home.title',
    component: makeLazy(() => import(
      /* webpackChunkName:"HomePage" */
      './screens/Home'
    )),
  }, {
    path: '/attribution',
    exact: true,
    title: 'attribution.title',
    component: makeLazy(() => import(
      /* webpackChunkName:"AttributionPage" */
      './screens/Attribution'
    )),
  }, {
    // 404 is intentionally homepage for now
    path: '/test',
    component: makeLazy(() => import(
      /* webpackChunkName:"AttributionPage" */
      './components/PageSuspense'
    )),
  }, {
    // 404 is intentionally homepage for now
    path: '*',
    title: 'home.title',
    component: makeLazy(() => import(
      /* webpackChunkName:"AttributionPage" */
      './screens/Home'
    )),
  },
];
