import { stoomlinkColors } from 'stoomlink-commons-ui/colors';

export default {
  colors: {
    light: {
      primary: stoomlinkColors.white.hex,
      primaryShade1: stoomlinkColors.Gray50.hex,
      primaryShade2: stoomlinkColors.Gray100.hex,
      primaryShade3: stoomlinkColors.Gray200.hex,
      primaryShade4: stoomlinkColors.Gray300.hex,
      secondary: stoomlinkColors.black.hex,
      secondaryShade1: stoomlinkColors.Gray900.hex,
      secondaryShade2: stoomlinkColors.Gray800.hex,
      secondaryShade3: stoomlinkColors.Gray700.hex,
      secondaryShade4: stoomlinkColors.Gray600.hex,

      backgroundPrimary: stoomlinkColors.white.hex,
      backgroundSecondary: stoomlinkColors.Gray50.hex,
      backgroundTertiary: stoomlinkColors.Gray100.hex,

      footnoteText: stoomlinkColors.Gray600.hex,
      footnoteLink: stoomlinkColors.Gray700.hex,

      stoomlinkColors,
    },
    dark: {
      primary: stoomlinkColors.black.hex,
      primaryShade1: stoomlinkColors.Gray900.hex,
      primaryShade2: stoomlinkColors.Gray800.hex,
      primaryShade3: stoomlinkColors.Gray700.hex,
      primaryShade4: stoomlinkColors.Gray600.hex,
      secondary: stoomlinkColors.white.hex,
      secondaryShade1: stoomlinkColors.Gray50.hex,
      secondaryShade2: stoomlinkColors.Gray100.hex,
      secondaryShade3: stoomlinkColors.Gray200.hex,
      secondaryShade4: stoomlinkColors.Gray300.hex,

      backgroundPrimary: stoomlinkColors.Gray900.hex,
      backgroundSecondary: stoomlinkColors.Gray800.hex,
      backgroundTertiary: stoomlinkColors.Gray700.hex,

      footnoteText: stoomlinkColors.Gray300.hex,
      footnoteLink: stoomlinkColors.Blue200.hex,

      stoomlinkColors,
    },
  },
};
